import React from 'react'
import styled from 'styled-components'
import H1 from "../library/H1.js"
import Caption from "../library/Caption"
import {Link} from 'gatsby'

const HeroSmall = ({pathname, img, strapiHomePage, black}) => {
  return (
    <HeroStyle pathname={pathname} img={img} black={black}>
      <HeroWrapper black={black}>
        <Caption>
            {
                strapiHomePage.breadcrumb.map((elem, key) => {
                    if (elem.url)
                        return (
                            <Link key={key} to={elem.url}>{elem.label} / </Link>
                        )
                    return elem.label
                })
            }
        </Caption>
        <H1Wrapper>
          <H1>{strapiHomePage.title}</H1>
        </H1Wrapper>
        {/*<Img src={arrow} alt=""/>*/}
      </HeroWrapper>
    </HeroStyle>
  )
}

const H1Wrapper = styled.div`
  margin-left: -2px;
  max-width: 57rem;
`

const HeroWrapper = styled.div`
    max-width: ${({ theme }) => theme.layout.xxLargeScreen};
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 0 2.4rem;
    gap: 1rem;
    padding: 17rem 2.4rem 3rem 2.4rem;
    @media ${({theme}) => theme.breakpoints.tablets_reverse} {
      padding: 13rem 2.4rem 2rem 2.4rem;
    }
    p {
      opacity: ${({black}) => black ? 0.4 : 1 };
    }
`

const HeroStyle = styled.div`
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
//  height: 50vh;
  min-height: ${({ pathname }) => pathname === '/news' ? '35rem' : pathname === '/contact-us' ? '35rem' : '50rem'};
  max-height: ${({ pathname }) => pathname === '/news' ? '35rem' : pathname === '/contact-us' ? '35rem' : '50rem'};
  color: ${({theme, black}) => black ? theme.colors.black : theme.colors.white};
  a {
    color: ${({theme, black}) => black ? theme.colors.black : theme.colors.white};
  }
  display: flex;
  justify-content: center;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
  height: 50vh;
  min-height: 20rem;
  max-height: 30rem;
 }
`

export default HeroSmall

