import React from 'react'
import styled from 'styled-components'

import H4 from '/src/components/library/H4'

import arrow from '/static/images/news/arrow.svg'

const News = ({datas, newsDetail = false}) => {
    return (
        <StyledNews>
            <Wrapper newsDetail={newsDetail}>
                <Articles newsDetail={newsDetail}>
                    {
                        datas.edges.map((data, key) => {
                            return (
                                <Article key={key}>
                                    {data.node.url ?
                                        <a href={data.node.url} target={'_blank'} rel={"noreferrer"}>
                                            <ImageWrapper>
                                                <ExtLink>
                                                    <img alt={'arrow'} src={arrow} width={'12.5px'} height={'12px'}/>
                                                </ExtLink>
                                                <Img
                                                    img={`${process.env.GATSBY_STRAPI_API_URL}${data.node.Image.url}`}/>
                                            </ImageWrapper>
                                            <TitleWrapper>
                                                <H4>{data.node.Title}</H4>
                                            </TitleWrapper>
                                            {/*<DateWrapper>*/}
                                            {/*<Body>{data.node.Date}</Body>*/}
                                            {/*</DateWrapper>*/}
                                        </a> : data.node.news_al_hail?.id ?
                                        <a href={`/news/${data.node.news_al_hail.id}`}>
                                        <span>
                                          <Img img={`${process.env.GATSBY_STRAPI_API_URL}${data.node.Image.url}`}/>
                                          <TitleWrapper>
                                            <H4>{data.node.Title}</H4>
                                          </TitleWrapper>
                                          {/*<DateWrapper>*/}
                                          {/*  <Body>{data.node.Date}</Body>*/}
                                          {/*</DateWrapper>*/}
                                        </span>
                                        </a> :
                                        <span>
                                          <Img img={`${process.env.GATSBY_STRAPI_API_URL}${data.node.Image.url}`}/>
                                          <TitleWrapper>
                                            <H4>{data.node.Title}</H4>
                                          </TitleWrapper>
                                                    {/*<DateWrapper>*/}
                                                    {/*  <Body>{data.node.Date}</Body>*/}
                                                    {/*</DateWrapper>*/}
                                        </span>
                                    }
                                </Article>
                            )
                        })
                    }
                </Articles>
            </Wrapper>
        </StyledNews>
    )
}

export default News

const Wrapper = styled.div`
  max-width: ${({theme}) => theme.layout.xxLargeScreen};
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  padding: ${({newsDetail}) => newsDetail ? "6.3rem 0rem" : "0rem 2.4rem 10rem 2.4rem"};
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    padding: 3.8rem 2.4rem;
    padding: ${({newsDetail}) => newsDetail ? "6rem 0rem" : "0rem 2.4rem 10rem 2.4rem"};
  }
`

const StyledNews = styled.div`
  display: flex;
  justify-content: center;

`

const Articles = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${({newsDetail}) => newsDetail ? 1 : 3}, 1fr);
  //flex-wrap: wrap;
  row-gap: ${({newsDetail}) => newsDetail ? "4.2rem" : "10.2rem"};
  column-gap: 12rem;
  justify-content: space-between;
  align-items: flex-start;
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7.8rem;
  }
  @media ${({theme}) => theme.breakpoints.very_large} {

  }
`

const Article = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    //padding: 0 1.6rem;
  }
`

const TitleWrapper = styled.div`
  margin-top: 1rem;
  //max-width: 30.5rem;
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    max-width: unset;
  }
  @media ${({theme}) => theme.breakpoints.very_large} {
    //max-width: 22.2rem;
  }
`

const ImageWrapper = styled.div`
  position: relative;
`

const ExtLink = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 12px;
  background-color: ${({ theme }) => theme.colors.link};
  width: 24px;
  height: 24px;
`

const Img = styled.div`
  border-radius: 1.2rem;
  background-image: url(${({img}) => img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 20.5rem;
  //width: 34.6rem;

  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    //width: calc(100vw - 3.2rem);
    //width: 28.8rem;

  }

  @media ${({theme}) => theme.breakpoints.very_large} {
    //width: 24rem;
    //height: 13.6rem;
  }
`
