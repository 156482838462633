import {graphql, useStaticQuery} from "gatsby";
import React, {useEffect} from 'react'

import HeroSmall from '/src/components/hero-small/HeroSmall.js'
import Layout from '../components/layout/en/Layout'
import Seo from '../components/seo'

import News from '/src/components/news/News'

const NewsPage = (props) => {
  const {strapiNewsPage, allStrapiLatestNews} = useStaticQuery(query);

  useEffect(() => {
      console.log(props.location.pathname)
  })

  return (
    <Layout path={props.location.pathname} black={true}>
      <Seo
        title={strapiNewsPage.metaTitle}
        description={strapiNewsPage.metaDescription}
        keywords={strapiNewsPage.metaKeywords} />
      <HeroSmall pathname={props.location.pathname} img={null} strapiHomePage={strapiNewsPage} black={true}/>
      <News datas={allStrapiLatestNews} />
    </Layout>
  )
}

export default NewsPage

const query = graphql`
    query {
        strapiNewsPage(locale: {eq: "en"}) {
            metaTitle
            metaDescription
            metaKeywords
            title
            subtitle
            breadcrumb {
              label
              url
            }
        }
        allStrapiLatestNews(filter: {locale: {eq: "en"}}, sort: {fields: position}) {
            edges {
                node {
                    url
                    Date
                    Image {
                        url
                    }
                    Title
                }
            }
        }
    }
`;
